import React from "react"
import {graphql} from "gatsby"
import Seo from "../components/_shared/Seo"
import SliceZone from "../components/SliceZone"

const AboutPage = ({data}) => {

    if (!data) return null

    const pageContent = data.prismicAboutpage || {};

    return (
        <>
            <SliceZone
                body={pageContent.data.body}
                page={pageContent.uid}
            />
        </>
    );
};

export const Head = ({ data }) => (
    <Seo title={data.prismicAboutpage.data.seo_title} />
);


export const query = graphql`
  query AboutPageQuery($id: String, $lang: String) {
    prismicAboutpage(id: { eq: $id }, lang: { eq: $lang }) {
        id
        uid
        lang
        data {
          page_title {
            richText
          }
          seo_title
          body {
            ... on PrismicAboutpageDataBodyHero {
              id
              slice_type
              primary {
                hero_title {
                  richText
                }
                hero_content
                background_image {
                  fluid {
                    src
                  }
                }
              }
            }
            ... on PrismicAboutpageDataBodyEmployeeGallery {
              id
              slice_type
              primary {
                title {
                  richText
                }
                content {
                  richText
                }
              }
              items {
                employee_title {
                  richText
                  text
                }
                employee_status {
                  richText
                }
                employee_comments {
                  richText
                }
                employee_desc {
                  richText
                }
                employee_photo {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
 
    }
  }
`

export default AboutPage



